<template lang="pug">
article.route.route--full-page.home
    transition(name="slow-fade" appear)
        HomeVideo(v-if="viewPortWidth('>=', 940) && this.$globals.playHomeVideoBool")
        .bg-container(v-else)
            picture
                source(media="(min-width:1440px)", srcset="@/assets/images/home/bgLarge.webp")
                source(media="(min-width:440px)", srcset="@/assets/images/home/bgMedium.webp")
                img.bg-image(src="@/assets/images/home/bgSmall.webp" alt="" aria-hidden="true")
    .content
        section.section
                .section-container
                    //-.wrap(v-if="!this.eventsDataLoading")
                    .wrap
                        .text
                                transition(name="slide-in-top" appear)
                                    h1.headline {{ $t("home.headline") }}
                                transition(name="slide-in-top" appear)
                                    p.lead {{ $t("home.lead") }}
                                SocialIcons(appear-delay=700)
                        .events-container
                            .events
                                transition(name="slide-in-top" appear)
                                    iframe.spotify-widget(title="Oh Sheet! podcast" style="border-radius:8px", src="https://open.spotify.com/embed/episode/2VFannDEQNGJVbni4xJlyb?utm_source=generator&theme=0", width="100%", height="152", frameborder="0", allowfullscreen, allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture", loading="lazy")
                                //-EventList(:events-data="eventsData" :events-shown="1" layout="column" appear-delay="1600")
                            //-transition(name="slide-in-top" appear)
                                router-link.router-link(@click="navigationToggleHandler", to='/events') {{ $t("home.eventslink") }}
</template>

<script>
import EventList from '@/components/EventList'
import getEvents from '@/js/mixins/getEvents.js'
import SocialIcons from '@/components/SocialIcons'
import checkBreakpoint from '@/js/mixins/checkBreakpoint.js'
import HomeVideo from '@/components/HomeVideo'

export default {
    name: 'Home',
    components: {
        EventList,
        SocialIcons,
        HomeVideo
    },
    mixins: [getEvents, checkBreakpoint],
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_utilities';

.home {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
    @include at(desktop) {
        align-items: center;
    }
}

.bg-container {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
}

.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 740px;
    width: 100%;

    @include at(mobileLarge) {
        height: 940px;
    }

    @include at(laptop) {
        height: 100%;
    }
}

.content {
    flex: 1;
}

.text {
    position: relative;
    margin-bottom: 24px;
}

.events-container {
    max-width: 500px;
    margin: 0 auto;
    min-height: 144px;
    max-height: 100vh;

}

.wrap {
    @include at(desktop) {
        margin-top: 250px;
    }
}

.events {
    margin-bottom: 16px;
}

.router-link {
    transition-delay: 1.7s;
}

.headline {
    font: 200 2.25rem/1.1 'Montserrat', 'Lora', 'Arial', sans-serif;
    text-transform: uppercase;
    text-align: center;
    text-shadow: $text-shadow;
    transition-delay: .2s;

    @include at(laptop) {
        font-size: 3.5rem;
    }

    @include at(laptopLarge) {
        font-size: 4.5rem;
    }
}

.lead {
    font: 400 1.2rem/1.1 'Quicksand', 'Arial', sans-serif;
    text-align: center;
    text-transform: lowercase;
    margin-bottom: 16px;
    text-shadow: $text-shadow;
    transition-delay: .4s;

    @include at(tablet) {
        font-size: 1.5rem;
    }

    @include at(laptop) {
        font-size: 2rem;
    }

    @include at(laptopLarge) {
        margin-bottom: 24px;
    }
}

.home-event {
    @include at(laptop) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, calc(100% + 40px));
        margin: 0;
        bottom: 0;
    }
}

.home-event .loader {
    transform: translateY(25px);
}

.social-icons {
    margin-bottom: 16px;

    @include at(laptopLarge) {
        margin-bottom: 24px;
    }

    @include at(desktop) {
        margin-bottom: 0;
    }
}

.spotify-widget {
    transition-delay: 1.4s;
    margin-bottom: 8px;
}
</style>
