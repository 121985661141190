<template lang="pug">
transition-group.event-list(tag="ul" name="slide-in-top" appear :class="`event-list--${layout}`")
    template(v-for="(event, index) in events" )
        li.event(v-if="index < eventsShown"
        :key="event.id" 
        :style="`transition-delay: ${calcDelay(index)}ms`" 
        :class="event.ongoing ? 'event--ongoing':''")
            .event-content
                .date
                    p.month {{ $d(event.start, 'month') }}
                    hr
                    p.day {{ $d(event.start, 'day') }}
                .info
                    h2.h2.summary
                        span
                            span(v-if="event.summary") {{ languageParser(event.summary) }}
                            span(v-else) {{ $t('events.summaryDefault') }}
                    p.location(v-if="event.location")
                        | {{ languageParser(event.location) }}
                    p.time
                        span
                            span {{ $d(event.start, event.sameDay ? 'hourMinute' : 'fullTime') }}&nbsp;
                            span - {{ $d(event.end, event.sameDay ? 'hourMinute' : 'fullTime') }}
                            span.status(v-if="index == 0 && event.ongoing" :class="event.status ? `status--${event.status}` : ''")
                                span.blob
                                template(v-if="event.status =='live'") {{ $t('events.live') }}
                                template(v-else) {{ $t('events.ongoing') }}
                p.description(v-if="event.description" v-html="languageParser(event.description)")
            .footer
                a.icon-link(v-if="event.url" :href="event.url ? event.url : '#'" target="_blank" rel="noopener noreferrer")
                    span(v-if="event.status == 'live'") {{ $t('events.eventLive') }}
                    span(v-else) {{ $t('events.eventPage') }}
                    img(v-svg-inline src="../assets/vector/open.svg" alt="" aria-hidden="true")

</template>

<script>
// @ is an alias to /src

let now = new Date()
const textLangSeparator = '|'
const urlSeparator = '@'

export default {
    name: 'EventList',
    props: {
        eventsData: {
            required: true
        },
        eventsShown: {
            type: Number,
            default: 10
        },
        layout: {
            type: String,
            default: 'grid'
        },
        appearDelay: {
            type: Number,
            default: 0
        }
    },
    methods: {
        languageParser(text) {
            if(text.includes(textLangSeparator)) {
                let relevantText = text

                relevantText = relevantText.split(textLangSeparator)
                let engText = relevantText[0]
                let hunText = relevantText[1]

                if(this.$root.$i18n.locale == 'en') {
                    return engText
                } else if(this.$root.$i18n.locale == 'hu') {
                    return hunText
                } else {
                    return relevantText
                }
            } else {
                return text
            }
        },
        calcDelay(index) {
            return index * 100 + parseInt(this.appearDelay)
        }
    },
    computed: {
        events() {
            let events = new Array()
            Object.keys(this.eventsData).forEach((item) => {
                let response = this.eventsData[item]
                let event = new Object()
                event.start = new Date(response.start)
                event.end = new Date(response.end)
                if (response.location){
                let respLocationArr = response.location.split(urlSeparator)
                    event.location = respLocationArr[0]
                    event.url = respLocationArr[1]
                }
                //- ongoing
                //- console.log("start:\t" + event.start, "\nnow:\t" + now, "\nend:\t" + event.end)
                event.ongoing = event.start <= now && event.end >= now ? true : false
                //- only ongoing or future events are needed
                if(event.ongoing || event.start >= now) {
                    event.id = response.id
                    event.summary = response.summary
                    //- description
                    event.description = response.description
                    //- sameDay
                    event.sameDay = event.start.getFullYear() === event.end.getFullYear() && event.start.getMonth() === event.end.getMonth() && event.start.getDate() === event.end.getDate() ? true : false
                    //- eventStatus
                    event.status = null
                    if(event.ongoing && event.url) {
                        event.status = "live"
                    } else if (event.ongoing) {
                        event.status = "ongoing"
                    }
                    events.push(event)
                }

            })

            //console.log('events', events)
            return events
        }
    }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/scss/_utilities';

.event-list {
    display: grid;
    gap: 16px;
    text-align: left;

    @include at(laptopMedium) {
        grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
        gap: 32px;
    }
}

.event-list--column {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.event {
    color: $dark-text;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    box-shadow: $button-shadow;
    border-radius: $rootBorderRadius;

    @include at(laptopLarge) {
        min-width: 440px;
    }
}

.event-content {
    padding: 12px;
    display: grid;
    gap: 0 12px;
    grid-template-columns: 64px 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    background-color: $contentBg-bright;
    border-top-right-radius: $rootBorderRadius;
    border-top-left-radius: $rootBorderRadius;
    
    
    @include at(laptop) {
        grid-template-columns:72px 1fr;
    }
}

.date {
    grid-column: 1/2;
    grid-row: 1/2;
    background-color: $contentBg-lightgreen;
    color: $contentBg-bright;
    text-transform: uppercase;
    padding: 8px 6px;
    text-align: center;
    align-self: start;
    border-radius: math.div($rootBorderRadius, 2);
    
    @include at(laptop) {
        padding: 12px 8px;
    }
}

.date hr {
    margin: 4px 0;
    opacity: 1;
    border: 0;
    border-top: 1px solid $contentBg-bright;

    @include at(laptop) {
        margin: 8px 0;
    }
}

.month {
    font-size: .875rem;
    font-weight: 700;
    line-height: 14px;
    margin: 0;

    @include at(laptop) {
        font-size: 1rem;
    }
}

.day {
    font: 500 2.0rem/26px "Quicksand", sans-serif;

    @include at(laptop) {
        font-size: 2.25rem;
    }
}

.info {
    grid-column: 2/3;
    grid-row: 1/3;
}

.summary {
    font: 500 1rem/1.1 "Montserrat", sans-serif;
    text-transform: none;
    margin-bottom: 6px;

    @include at(laptop) {
        font-size: 1.25rem;
        margin-bottom: 10px;
    }
}

.time,
.location {
    font: 300 .875rem "Quicksand", sans-serif;
    margin-bottom: 4px;

    @include at(laptop) {
        font-size: 1rem;
        margin-bottom: 8px;
    }
}

.time {
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.time > span {
    position: relative;
}

.footer {
    display: flex;
    align-items: center;
    background-color: $contentBg-lightgreen;
    padding: 0 12px;
    height: 24px;
    border-bottom-left-radius: $rootBorderRadius;
    border-bottom-right-radius: $rootBorderRadius;
    
    @include at(laptop) {
        height: 32px;
        padding: 0 12px;
    }
}

.footer a {
    margin-left: auto;
    display: flex;
    line-height: 23px;
    align-items: center;
    font-size: .875rem;

    @include at(laptop) {
        line-height: 32px;
        font-size: 1rem;
    }
}

.description {
    grid-column: 1/-1;
    grid-row: 3;
    margin-top: 8px;
    font-size: .875rem;

    @include at(laptop) {
        font-size: 1rem;
    }
}

//ongoing event stlyes
.event--ongoing .event-content {
    color: $bright-text;
    background-color: $contentBg-lightgreen;
}

.event--ongoing .date {
    color: $contentBg-lightgreen;
    background-color: $contentBg-bright;
}

.event--ongoing .date hr {
    border-color: $contentBg-lightgreen;
}

.event--ongoing .footer {
    background-color: $contentBg-bright;
}

.event--ongoing .footer a {
    color: $contentBg-lightgreen;
}

.event--ongoing .footer a svg {
    fill: $contentBg-lightgreen;
}

.status {
    font-family: 'Montserrat';
    background-color: $contentBg-lightgreen;
    border: 1px solid $contentBg-bright;
    border-radius: math.div($rootBorderRadius, 2);
    display: inline-flex;
    margin-left: 8px;
    color: $bright-text;
    align-items: center;
    text-transform: uppercase;
    line-height: 14px;
    padding: 0 3px;
    font-size: 10px;
    align-self: center;
    position: absolute;
    right: -8px;
    top: 0;
    transform: translateX(100%);

    @include at(laptop) {
        line-height: 16px;
        font-size: 12px;
        padding: 2px 4px;
    }
}

.status--live {
    background-color: $redStatus;
    border-color: transparent;
}

.blob {
    display: block;
    border-radius: 50%;
    margin-right: 4px;
    height: 6px;
    width: 6px;
    transform: scale(1);
    background: white;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse-white 2s infinite;

    @include at(laptop) {
        height: 8px;
        width: 8px;
    }
}
</style>